import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Studenflak({ data }) {
  const title = "Studentmössa - Allt som du behöver veta";
  return (
    <>
      <Page title={title}>
        <article className="container px-5 py-6 mx-auto info-content">
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            className="mb-5 rounded-xl"
            alt="studentmössa"
          />

          <h1 className="text-4xl text-gray-900 font-bold md:text-5xl">
            {title}
          </h1>
          <p>
            En studentmössa är ett måste när man tar student och det finns flera
            olika bolag som säljer studentmössor. De populäraste alternativen är{" "}
            <strong>
              ABC-Gruppen, Galio of Sweden och C.L Seifert
            </strong>
            .
          </p>
          <p>Nedan hittar du en kort sammanfattning kring dessa olika alternativen.</p>
          <section className="">
            <div className="py-4 mx-auto max-w-screen-xl">
              <div className="space-y-8 lg:grid lg:grid-cols-3 gap-6 lg:space-y-0">
                <div className="flex flex-col p-4 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow ">
                  <h3 className="mb-4 text-2xl font-semibold">ABC-Gruppen</h3>
                  <p className="font-light text-gray-500 sm:text-lg  mb-4"><b>Studentmössa från 499 kr</b></p>
                  <ul role="list" className="mb-8 space-y-4 text-left">
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Designa studentmössa</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>100% storleksgaranti</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Hög kvalité</span>
                    </li>

                  </ul>
                  <a href="https://www.abcgruppen.se/" target="_blank" rel="nofollow" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center  ">Gå till ABC-Gruppen</a>
                </div>
                <div className="flex flex-col p-4 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
                  <h3 className="mb-2 text-2xl font-semibold">Galio of Sweden</h3>
                  <p className=" text-gray-500 sm:text-lg "><b>Studentmössa från 395 kr</b></p>
                  <ul role="list" className="space-y-4 text-left">
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Designa studentmössa</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Hundratals designval</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Hög kvalité</span>
                    </li>

                  </ul>
                  <a href="https://galio.se/student" target="_blank" rel="nofollow" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Gå till Galio</a>
                </div>
                <div className="flex flex-col p-4 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
                  <h3 className="mb-4 text-2xl font-semibold">C.L Seifert</h3>
                  <p className="font-light text-gray-500 sm:text-lg  mb-4"><b>Studentmössa från 395 kr</b></p>
                  <ul role="list" className="mb-8 space-y-4 text-center">
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Designa studentmössa</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Snabba leveranser</span>
                    </li>
                    <li className="flex items-center space-x-3">
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Hög kvalité</span>
                    </li>

                  </ul>
                  <a href="https://clseifert.com/sv/" target="_blank" rel="nofollow" className="text-white bg-emerald-600 hover:bg-emerald-700 focus:ring-4 focus:ring-emerald-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center  ">Gå till C.L Seifert</a>
                </div>

              </div>
            </div>
          </section>
          <p>
            När du ska köpa en studentmössa behöver du också fundera över design
            och vilket material som studentmössan ska vara i. Det brukar också
            finnas mängder av tillval såsom symboler, texter och juveler.
          </p>
          <p>
            I denna artikeln kommer vi gå igenom allt som du behöver veta när du
            ska köpa din studentmössa.
          </p>
          <h2 id="vilken-studentm-ssa-ska-man-k-pa-">
            Vilken studentmössa ska man köpa?
          </h2>
          <p>
            Många av studentmössa-företagen brukar komma till olika gymnasium
            och dela ut information om alla deras möss-modeller. Detta är det
            enklaste sättet att hitta en studentmössa som du gillar, men du
            behöver verkligen inte köpa en studentmössa av det företaget.
          </p>
          <p>
            Du kan hitta de flesta företagen på nätet och beställa din
            studentmössa därifrån. Det ger dig möjligheten att jämföra flera
            olika företag för studentmössor så att du kan du hitta det bästa
            erbjudandet och den bästa studentmössan för dig.
          </p>
          <p>Här är några av de mest populära företagen för studentmössor:</p>
          <ul>
            <li className="font-bold">ABC-Gruppen</li>
            <li className="font-bold">Crownstudent</li>
            <li className="font-bold">Galio of Sweden</li>
            <li className="font-bold">C.L Seifert</li>
          </ul>
          <p>
            Det finns också några andra företag som erbjuder billigare studentmössor. Det går exempelvis att köpa en <a href="https://click.adrecord.com/?c=155221&p=665&url=https://www.partykungen.se/studentmossa-25231.html" rel="nofollow" target="_blank">studentmössa för 100 kr</a> på Partykungen. En nackdel är däremot att dessa studentmössor inte alls har lika bra kvalitet som de dyrare studentmössorna.
          </p>
          <p>
            Förutom vilket bolag som du ska köpa från, så behöver du också för
            att bestämma design, texter, och material för din studentmössa.
          </p>

          <h3 id="f-rg-p-m-ssan">Färg på mössan</h3>
          <p>
            Det finns många olika färger för studentmössor, men de vanligaste är
            antingen vita eller svarta, där de vita är de mest populära. Du kan
            också hitta studentmössor i andra färger, men dessa två färger är de
            som rekommenderas.
          </p>
          <p>
            Den vita studentmössan är den mest populära eftersom vitt är en färg
            som symboliserar renhet, oskuldsfullt och en ny början. Det är också
            en färg som förknippas med examen.
          </p>
          <p>
            En svart studentmössa är också ett populärt val eftersom svart är en
            klassisk färg som aldrig går ur tiden. Svart förknippas också med
            makt, auktoritet och styrka.
          </p>
          <h3 id="programf-rger">Programfärger</h3>
          <p>
            En studentmössa har alltid en färgad sten på framsidan, som
            representerar färgen på ditt program.
          </p>
          <p>
            Nedan går vi igenom vilken färg som du ska välja beroende på vilket
            program som du har läst på gymnasiet.
          </p>
          <ul>
            <li className="font-bold">Teknikprogrammet - Marinblå</li>
            <li className="font-bold">Handel- och Administrationsprogrammet - Röd</li>
            <li className="font-bold">Estetprogrammet - Lila</li>
            <li className="font-bold">Industriprogrammet - Vit</li>
            <li className="font-bold">Naturvetenskapsprogrammet - Grön</li>
            <li className="font-bold">Barn- &amp; Fritidsprogrammet - Svart</li>
            <li className="font-bold">Ekonomiprogrammet - Silver</li>
            <li className="font-bold">Samhällsprogrammet - Royalblå</li>
            <li className="font-bold">Fordonsprogrammet - Ljusgrå</li>
          </ul>
          <p>
            Självklart så kan du också välja en annan färg, men de allra flesta
            brukar hålla sig till programmets färger.
          </p>
          <h3 id="text-p-m-ssan">Text på mössan</h3>
          <p>
            Du kan också välja att få text broderad på din studentmössa. Detta
            är vanligtvis ditt eget namn eller ett kort meddelande om examen.
          </p>
          <p>Det är också vanligt att skriva följande på studentmössan:</p>
          <ul>
            <li className="font-bold">Skolans namn</li>
            <li className="font-bold">Klassförteckning/linje</li>
            <li className="font-bold">Ett smeknamn</li>
            <li className="font-bold">Ditt snapchat-namn</li>
            <li className="font-bold">Årtalet du tar studenten</li>
            <li className="font-bold">
              Kort meddelande som &quot;Äntligen klar!&quot;, &quot;Vi klarade
              det!&quot; eller &quot;Vi tog examen!&quot;.
            </li>
          </ul>
          <p>
            Vanligtvis skriver du ditt namn som brodyr fram på studentmössan
            medan på baksidan av studentmössan finns det en annan text såsom
            klassens namn eller ett kort meddelande.
          </p>
          <p>Om du vill ha inspriation vad man kan skriva på studentmössan kan du läsa vår artikel - <a href="/inspiration-text/">Inspiration och texter för studentmössan</a>.</p>
          <h3 id="toppemblem">Toppemblem</h3>
          <p>
            Du kan också låta brodera toppemblem på studentmössan. Den
            vanligaste symbolen är en examenshatt, men du kan också ha andra
            symboler som ett hjärta, stjärnor, blommor eller vad du vill.
          </p>
          <p>
            När du designar din hemsida brukar det finnas massor av olika
            toppemblem att välja mellan. Du kan exempelvis välja något som
            symboliserar ditt program eller något som du själv tycker passar din
            stil.
          </p>
          <h2 id="vilken-kostar-en-studentm-ssa-">
            Vad kostar en studentmössa?
          </h2>
          <iframe width="100%" height="500" className="rounded-xl" src="https://www.youtube.com/embed/_YYSlyL9NyA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <p>
            Priset på en studentmössa varierar väldigt mycket och en
            studentmössa kostar vanligtvis mellan <b>400 och 1500 kronor</b>.
          </p>
          <p>
            Priset beror på många olika faktorer, t.ex. vilket företag du köper
            från, hur studentmössan är utformad, kvalité och om du vill lägga
            till några extra saker, t.ex. broderi eller toppemblem.
          </p>
          <p>
            Om du har en stram budget kommer du förmodligen behöva välja en
            enklare konstruktion och köpa från ett billigare företag. Om du vill
            ha en lyxigare studentmössa måste du vara beredd att lägga ut mer
            pengar.
          </p>
          <p>
            Du behöver inte känna att du behöver lägga hur mycket som helst på
            en studentmössa. Det är faktiskt endast något som du kommer använda
            en enda dag under ditt liv så det är inte värt att lägga ner för
            mycket pengar på det. Se bara till att du är nöjd med mössans design
            och kvalitet innan du köper den.
          </p>
          <h2 id="n-r-ska-man-best-lla-sin-studentm-ssa-">
            När ska man beställa sin studentmössa?
          </h2>
          <p>
            Studentmössan är en skräddarsydd produkt så det är viktigt att du
            beställer din studentmössa i god tid. Det bästa är att beställa den
            några veckor innan du tar studenten så vänta inte för länge innan du
            beställer.
          </p>
          <p>
            Om du beställer din studentmössa för sent finns det en risk att den
            inte kommer fram i tid eller att företaget inte kan uppfylla dina
            krav. Se därför till att beställa den så snart du kan för att
            undvika stressiga problem i sista minuten.
          </p>
          <p>
            Vi hoppas att den här guiden har hjälpt dig att förstå allt du
            behöver veta om studentmössor.
          </p>
        </article>
      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "studentmossa1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
